import { TTree, ITrackStateTree, IMutationTree } from './types'
import isPlainObject from 'is-plain-obj'

export const IS_PROXY = Symbol('IS_PROXY')
export const PATH = Symbol('PATH')
export const VALUE = Symbol('VALUE')

const arrayMutations = new Set([
  'push',
  'shift',
  'pop',
  'unshift',
  'splice',
  'reverse',
  'sort',
  'copyWithin',
])

const getValue = (proxyOrValue) =>
  proxyOrValue && proxyOrValue[IS_PROXY] ? proxyOrValue[VALUE] : proxyOrValue

export class Proxifier {
  CACHED_PROXY = Symbol('CACHED_PROXY')
  constructor(private tree: TTree) {}
  private concat(path, prop) {
    return path ? path + '.' + prop : prop
  }

  ensureMutationTrackingIsEnabled(path) {
    if (process.env.NODE_ENV === 'production') return

    if (this.tree.master.options.devmode && !this.tree.canMutate()) {
      throw new Error(
        `proxy-state-tree - You are mutating the path "${path}", but it is not allowed. The following could have happened:
        
        - You are passing state to a 3rd party tool trying to manipulate the state
        - You are running an asynchronous action from an other action where you forgot to "await" it
        `
      )
    }
  }

  isDefaultProxifier() {
    return this.tree.proxifier === this.tree.master.proxifier
  }

  ensureValueDosntExistInStateTreeElsewhere(value) {
    if (process.env.NODE_ENV === 'production') return

    if (value && value[IS_PROXY] === true) {
      throw new Error(
        `proxy-state-tree - You are trying to insert a value that already exists in the state tree on path "${
          value[PATH]
        }"`
      )
    }

    return value
  }

  trackPath(path: string) {
    if (!this.tree.canTrack()) {
      return
    }

    if (this.isDefaultProxifier()) {
      const trackStateTree = this.tree.master.currentTree as ITrackStateTree<
        any
      >

      if (!trackStateTree) {
        return
      }

      trackStateTree.addTrackingPath(path)
    } else {
      ;(this.tree as ITrackStateTree<any>).addTrackingPath(path)
    }
  }
  // With tracking trees we want to ensure that we are always
  // on the currently tracked tree. This ensures when we access
  // a tracking proxy that is not part of the current tracking tree (pass as prop)
  // we move the ownership to the current tracker
  getTrackingTree() {
    if (this.tree.master.currentTree && this.isDefaultProxifier()) {
      return this.tree.master.currentTree
    }

    if (!this.tree.canTrack()) {
      return null
    }

    if (this.tree.canTrack()) {
      return this.tree
    }

    return null
  }
  getMutationTree() {
    return this.tree.master.mutationTree || (this.tree as IMutationTree<any>)
  }
  private createArrayProxy(value, path) {
    if (value[this.CACHED_PROXY] && String(value[PATH]) === String(path)) {
      return value[this.CACHED_PROXY]
    }

    const proxifier = this

    const proxy = new Proxy(value, {
      get(target, prop) {
        if (prop === IS_PROXY) return true
        if (prop === PATH) return path
        if (prop === VALUE) return value
        if (prop === 'indexOf') {
          return (searchTerm, offset) =>
            value.indexOf(getValue(searchTerm), getValue(offset))
        }
        if (
          prop === 'length' ||
          (typeof target[prop] === 'function' &&
            !arrayMutations.has(String(prop))) ||
          typeof prop === 'symbol'
        ) {
          return target[prop]
        }

        const trackingTree = proxifier.getTrackingTree()
        const nestedPath = proxifier.concat(path, prop)
        const currentTree = trackingTree || proxifier.tree

        trackingTree && trackingTree.proxifier.trackPath(nestedPath)
        currentTree.trackPathListeners.forEach((cb) => cb(nestedPath))

        const method = String(prop)

        if (arrayMutations.has(method)) {
          /* @__PURE__ */ proxifier.ensureMutationTrackingIsEnabled(nestedPath)
          return (...args) => {
            const mutationTree = proxifier.getMutationTree()

            mutationTree.addMutation({
              method,
              path: path,
              args: args,
              hasChangedValue: true,
            })

            if (process.env.NODE_ENV === 'production') {
              return target[prop](...args)
            } else {
              return target[prop](
                ...args.map((arg) =>
                  /* @__PURE__ */ proxifier.ensureValueDosntExistInStateTreeElsewhere(
                    arg
                  )
                )
              )
            }
          }
        }

        if (target[prop] === undefined) {
          return undefined
        }

        return proxifier.proxify(target[prop], nestedPath)
      },
      set(target, prop, value) {
        const nestedPath = proxifier.concat(path, prop)

        /* @__PURE__ */ proxifier.ensureMutationTrackingIsEnabled(nestedPath)
        /* @__PURE__ */ proxifier.ensureValueDosntExistInStateTreeElsewhere(
          value
        )

        const mutationTree = proxifier.getMutationTree()

        mutationTree.addMutation({
          method: 'set',
          path: nestedPath,
          args: [value],
          hasChangedValue: true,
        })

        return Reflect.set(target, prop, value)
      },
    })

    Object.defineProperty(value, this.CACHED_PROXY, {
      value: proxy,
      configurable: true,
    })

    return proxy
  }

  private createObjectProxy(object, path) {
    if (object[this.CACHED_PROXY] && String(object[PATH]) === String(path)) {
      return object[this.CACHED_PROXY]
    }

    const proxifier = this

    const proxy = new Proxy(object, {
      get(target, prop) {
        if (prop === IS_PROXY) return true
        if (prop === PATH) return path
        if (prop === VALUE) return object

        if (typeof prop === 'symbol' || prop in Object.prototype)
          return target[prop]

        const descriptor = Object.getOwnPropertyDescriptor(target, prop)

        if (descriptor && 'get' in descriptor) {
          const value = descriptor.get.call(object[proxifier.CACHED_PROXY])

          if (
            proxifier.tree.master.options.devmode &&
            proxifier.tree.master.options.onGetter
          ) {
            proxifier.tree.master.options.onGetter(
              proxifier.concat(path, prop),
              value
            )
          }

          return value
        }

        const trackingTree = proxifier.getTrackingTree()
        const targetValue = target[prop]
        const nestedPath = proxifier.concat(path, prop)
        const currentTree = trackingTree || proxifier.tree

        if (typeof targetValue === 'function') {
          return proxifier.tree.master.options.dynamicWrapper
            ? proxifier.tree.master.options.dynamicWrapper(
                trackingTree || proxifier.tree,
                nestedPath,
                targetValue
              )
            : targetValue(proxifier.tree, nestedPath)
        } else {
          currentTree.trackPathListeners.forEach((cb) => cb(nestedPath))
          trackingTree && trackingTree.proxifier.trackPath(nestedPath)
        }

        if (targetValue === undefined) {
          return undefined
        }

        return proxifier.proxify(targetValue, nestedPath)
      },
      set(target, prop, value) {
        const nestedPath = proxifier.concat(path, prop)

        /* @__PURE__ */ proxifier.ensureMutationTrackingIsEnabled(nestedPath)
        /* @__PURE__ */ proxifier.ensureValueDosntExistInStateTreeElsewhere(
          value
        )

        let objectChangePath

        if (!(prop in target)) {
          objectChangePath = path
        }

        const mutationTree = proxifier.getMutationTree()

        mutationTree.addMutation(
          {
            method: 'set',
            path: nestedPath,
            args: [value],
            hasChangedValue: value !== target[prop],
          },
          objectChangePath
        )

        if (typeof value === 'function') {
          return Reflect.set(target, prop, () => value)
        }

        return Reflect.set(target, prop, value)
      },
      deleteProperty(target, prop) {
        const nestedPath = proxifier.concat(path, prop)

        /* @__PURE__ */ proxifier.ensureMutationTrackingIsEnabled(nestedPath)

        let objectChangePath
        if (prop in target) {
          objectChangePath = path
        }

        const mutationTree = proxifier.getMutationTree()

        mutationTree.addMutation(
          {
            method: 'unset',
            path: nestedPath,
            args: [],
            hasChangedValue: true,
          },
          objectChangePath
        )

        delete target[prop]

        return true
      },
    })

    Object.defineProperty(object, this.CACHED_PROXY, {
      value: proxy,
      configurable: true,
    })

    return proxy
  }
  proxify(value: any, path: string) {
    if (value) {
      const isUnmatchingProxy =
        value[IS_PROXY] &&
        (String(value[PATH]) !== String(path) ||
          value[VALUE][this.CACHED_PROXY] !== value)

      if (isUnmatchingProxy) {
        return this.proxify(value[VALUE], path)
      } else if (value[IS_PROXY]) {
        return value
      } else if (isPlainObject(value)) {
        return this.createObjectProxy(value, path)
      } else if (Array.isArray(value)) {
        return this.createArrayProxy(value, path)
      }
    }

    return value
  }
}
