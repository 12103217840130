import { EventEmitter } from 'betsy'
import {
  ProxyStateTree,
  VALUE,
  IS_PROXY,
  ITrackStateTree,
  TrackStateTree,
  IMutationTree,
  MutationTree,
} from 'proxy-state-tree'

import { EventType, Events } from './internalTypes'

export class Derived {
  private isDirty: boolean = true
  private trackStateTree: ITrackStateTree<any>
  private previousProxifier: any
  private value: any
  private paths: Set<string>
  private updateCount: number = 0
  private disposeOnMutation: () => void
  constructor(private cb: (state: object, parent: object) => void) {
    const boundEvaluate: any = this.evaluate.bind(this)

    if (process.env.NODE_ENV === 'development') {
      boundEvaluate.dispose = () => {
        this.disposeOnMutation()
      }
    }

    return boundEvaluate
  }
  private runScope(tree, path) {
    const pathAsArray = path.split('.')
    pathAsArray.pop()
    const parent = pathAsArray.reduce((curr, key) => curr[key], tree.state)

    return this.cb(parent, tree.state)
  }
  evaluate(
    eventHub: EventEmitter<Events>,
    tree: ITrackStateTree<any> | IMutationTree<any>,
    proxyStateTree: ProxyStateTree<any>,
    path
  ) {
    if (!this.disposeOnMutation) {
      this.disposeOnMutation = proxyStateTree.onMutation(
        (_, paths, flushId) => {
          if (this.isDirty) {
            return
          }

          for (let mutationPath of paths) {
            if (this.paths.has(mutationPath)) {
              this.isDirty = true
              eventHub.emitAsync(EventType.DERIVED_DIRTY, {
                derivedPath: path,
                path: mutationPath,
                flushId,
              })
              return
            }
          }
        }
      )
    }

    // During development we need to move the ownership of whatever state is returned from
    // the derived to track it correctly. In production we only have one proxifier, so no worries
    if (this.isDirty || this.previousProxifier !== tree.proxifier) {
      const getPaths = tree.trackPaths()

      this.value = this.runScope(tree, path)
      this.isDirty = false
      this.paths = getPaths()

      if (process.env.NODE_ENV === 'development') {
        eventHub.emitAsync(EventType.DERIVED, {
          path,
          paths: Array.from(this.paths),
          updateCount: this.updateCount,
          value: this.value,
        })
        this.updateCount++
      }
    }

    if (tree instanceof TrackStateTree) {
      // If we access a cached value we have to make sure that we move
      // the tracked paths into the tree looking at it, where
      // addTrackingPath is for initial tree and "trackPathListeners"
      // is for nested derived
      for (let path of this.paths) {
        tree.addTrackingPath(path)
        tree.trackPathListeners.forEach((cb) => cb(path))
      }
    }

    this.previousProxifier = tree.proxifier

    return this.value
  }
}
