import { IS_PROXY } from 'proxy-state-tree'
import isPlainObject from 'is-plain-obj'

export type Message = {
  type: string
  data?: object
}

export type DevtoolsMessage = {
  type: string
  appName: string
  data: any
}

export function safeValue(value) {
  if (typeof value === 'function') {
    return '[Function]'
  }

  if (
    typeof value === 'object' &&
    !Array.isArray(value) &&
    value !== null &&
    !isPlainObject(value)
  ) {
    return `[${value.constructor.name || 'NOT SERIALIZABLE'}]`
  }

  return value && !value[IS_PROXY] && isPlainObject(value)
    ? Object.keys(value).reduce((aggr, key) => {
        aggr[key] = safeValue(value[key])

        return aggr
      }, {})
    : value
}

export function safeValues(values) {
  return values.map(safeValue)
}

export class Devtools {
  private buffer: string[] = []
  private ws: WebSocket
  private isConnected: boolean = false
  private doReconnect: boolean = false
  private hasWarnedReconnect: boolean = false
  private reconnectInterval: number = 10000
  private name: string
  constructor(name: string) {
    this.name = name
  }
  connect = (host: string, onMessage: (message: Message) => void) => {
    host = host || 'localhost:3031'

    this.ws = new WebSocket(`ws://${host}?name=${this.name}`)
    this.ws.onmessage = (event) => onMessage(JSON.parse(event.data))
    this.ws.onopen = () => {
      this.isConnected = true
      this.sendBuffer()
    }
    this.ws.onerror = () => {}
    this.ws.onclose = () => {
      this.isConnected = false

      if (this.doReconnect && !this.hasWarnedReconnect) {
        console.warn(
          'Debugger application is not running on selected port... will reconnect automatically behind the scenes'
        )
        this.hasWarnedReconnect = true
      }

      if (this.doReconnect) {
        this.reconnect(host, onMessage)
      }
    }
  }
  private reconnect(host, onMessage) {
    setTimeout(
      () =>
        this.connect(
          host,
          onMessage
        ),
      this.reconnectInterval
    )
  }
  send(message: Message) {
    const stringifiedMessage = JSON.stringify(message)
    this.buffer.push(stringifiedMessage)
    this.sendBuffer()
  }
  private sendBuffer = () => {
    if (this.isConnected && this.buffer.length) {
      this.ws.send(
        `{ "appName": "${this.name}", "message": ${this.buffer.shift()} }`
      )

      if (this.buffer.length) {
        setTimeout(this.sendBuffer)
      }
    }
  }
}
